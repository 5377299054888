import React, {lazy} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux'

const App = lazy(() => import('./App'));
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {store} from './app/store'
import {productsFetch} from "./features/productSlice";
import {getTotals} from "./features/cartSlice";
import './i18n'

const container = document.getElementById('root');
const root = createRoot(container);
store.dispatch(productsFetch);
store.dispatch(getTotals());
import {RecoilRoot} from 'recoil';

root.render(
    <RecoilRoot>
        <Provider store={store}>
            <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <App/>


                </LocalizationProvider>
            </BrowserRouter>
        </Provider>
    </RecoilRoot>
);
